/* Tailwind Styling */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Common Styling Start */
* {
    @apply m-0 box-border p-0 outline-none text-base;
}

body {
    @apply antialiased text-base;
    font-family: 'Poppins', sans-serif;
	background-image: url("../../../public/scanofe_bg.jpg");
	background-color: rgba(255, 255, 255, 0.9); 
    background-blend-mode: lighten;
    background-position: center;
    background-repeat: no-repeat; 
	background-size: auto;
}

html {
    @apply scroll-smooth;
}

img {
    @apply max-w-full;
}

ul {
    @apply list-none;
}

/* Scrollbar styling */

::-webkit-scrollbar-track {
    @apply bg-[#ededed] border-l border-[#ddd];
}

::-webkit-scrollbar {
    @apply w-1.5 bg-[#eee] h-1.5;
}

::-webkit-scrollbar-thumb {
    @apply rounded-md bg-[#b9b9b9];
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Common Styling End */

/* Custom Select Arrow */
.custom-arrow {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 99.50%;
    background-position-y: 6px;
    background-size: 28px;
}

.font-math {
	font-family: math;
}

.svg-height-adjust svg {
	width: 200px;
	height: 400px;
}

@media screen and (max-width: 1370px) {

	.svg-height-adjust {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.svg-height-adjust svg {
		width: 200px;
		height: 280px;
	}
  }

/* Define a media query for tablet screens */
@media (min-width:  768px) and (max-width:  1370px) {
	.tablet-margin {
	  margin-top:  3.5rem; /*  14 units in rem */
	  margin-bottom:  1.25rem; /*  5 units in rem */
	}
  }

/* Custom Styling for DatePicker */
.react-datepicker-wrapper {
	width: 100%;
  }
  
  .react-datepicker__input-container input {
	width: 100%;
	padding: 10px;
	font-size: 16px;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
  }
  
  .react-datepicker__input-container input:focus {
	border-color: #909090;
	outline: none;
  }
  
  /* Custom Calendar Popup */
  .custom-datepicker {
	font-family: 'Poppins', sans-serif;
	border-radius: 8px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    gap: 8px;
  }
  
  /* Hide the default checkbox */
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* Custom checkbox */
  .custom-checkbox .checkmark {
    width: 20px;
    height: 20px;
    border: 2px solid #555;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    transition: all 0.2s ease-in-out;
  }
  
  /* Change color when checked */
  .custom-checkbox input:checked + .checkmark {
    background-color: #0d1838;
    border-color: #0d1838;
  }
  
  /* Add checkmark when checked */
  .custom-checkbox input:checked + .checkmark::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -65%) rotate(45deg);
  }