.ais-SearchBox-input {
  display: block;
  padding: 1rem;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 2.2rem;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: #d1d5db;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #111827;
  font-size: 16px;
}

.maxWidth{
  width: calc(100vw - 200px);
}

.ais-SearchBox {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

#search-clear {
	cursor: pointer;
}


.ais-SearchBox-reset {
  display: none;
}
.ais-SearchBox-submit {
  display: none;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Loader.css */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.399); /* Semi-transparent white background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the loader is above other content */
}

.loader-spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid #03254c; /* Blue border for spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
