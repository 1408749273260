@font-face {
    font-family: 'Poppins Light';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), url('Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), url('Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'), url('Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins SemiBold';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins SemiBold'), url('Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'), url('Poppins-Bold.ttf') format('truetype');
}
