/* Custom phone input with flag and prefix on the left */
.custom-phone-input-wrapper {
  display: flex;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 20px;
  height: 46px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: visible;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.custom-phone-input-wrapper:focus-within {
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.15);
}

.custom-phone-prefix {
  display: flex;
  align-items: center;
  padding: 0 8px;
  background-color: white;
  border-right: 1px solid #e0e0e0;
  min-width: 90px;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
}

.custom-phone-prefix:hover {
  background-color: #f8f9fa;
}

.country-flag {
  width: 24px;
  height: 18px;
  margin-right: 5px;
}

.country-code {
  font-size: 14px;
  color: #333;
  margin-right: 5px;
}

.dropdown-arrow {
  margin-left: 2px;
}

.custom-phone-field {
  flex: 1;
  border: none;
  padding: 0 15px;
  font-size: 14px;
  outline: none;
  height: 100%;
}

/* Make sure the input is visually connected to the prefix */
.custom-phone-field:focus {
  outline: none;
}

/* Country dropdown styling */
.country-dropdown {
  position: absolute;
  top: 46px;
  left: 0;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.country-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.country-item:last-child {
  border-bottom: none;
}

.country-item:hover {
  background-color: #f8f9fa;
}

/* Add highlighting for the selected country in the dropdown */
.country-item.selected {
  background-color: #f0f7ff;
}

.country-item-left {
  display: flex;
  align-items: center;
}

.country-flag-small {
  width: 24px;
  height: 18px;
  margin-right: 10px;
}

.country-name {
  font-size: 14px;
  color: #333;
}

.country-dial-code {
  font-size: 14px;
  color: #666;
  margin-left: 10px;
}

/* Hide the original react-international-phone component */
.react-international-phone-input {
  width: 100% !important;
  height: 46px !important;
}

.react-international-phone-input-container {
  display: flex;
  align-items: center;
}

.react-international-phone-country-selector-button {
  height: 46px !important;
}

/* Custom styles for the phone input container */
.custom-phone-input {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
}

.custom-phone-component {
  width: 100% !important;
}

.react-international-phone-input-container {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
}

/* Apply styles to make the input field and dropdown look similar to the image */
.react-international-phone-country-selector-button {
  display: flex;
  align-items: center;
  padding: 0 10px !important;
}

.react-international-phone-country-selector-button img {
  margin-right: 5px !important;
}

.react-international-phone-country-selector-dropdown {
  max-height: 300px;
  overflow-y: auto;
}

/* Fix for the input field to avoid double borders */
.react-international-phone input {
  outline: none !important;
  padding-left: 10px !important;
}

