/* GuidePage specific styles */

/* Mobile styles */
@media (max-width: 767px) {
  .step-navigation {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .step-indicator {
    display: flex;
    overflow-x: auto;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
  }
  
  .step-indicator::-webkit-scrollbar {
    height: 4px;
  }
  
  .step-indicator::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .step-indicator::-webkit-scrollbar-thumb {
    background: #03254c;
    border-radius: 4px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 991px) {
  .step-content {
    padding: 0 15px;
  }
  
  .step-navigation-tablet {
    width: 33.333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
  }
  
  /* Ensure text is properly aligned */
  .step-content-text {
    text-align: left;
    padding-right: 10px;
  }
}

/* Sticky navbar */
.navbar-sticky {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Ensure the fixed sidebar has proper height accounting for sticky navbar */
.sidebar-fixed {
  height: calc(100vh - 109px);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  overflow-y: auto;
}

/* Center the navigation vertically (for taller screens) */
@media (min-height: 700px) {
  .sidebar-fixed {
    justify-content: center;
  }
}

/* For shorter screens, just use top padding instead of centering to avoid items being cut off */
@media (max-height: 699px) {
  .sidebar-fixed {
    justify-content: flex-start;
    padding-top: 2.5rem;
  }
}

/* Hover effects and animations */
.step-button {
  transition: all 0.2s ease-in-out;
}

.step-button:hover {
  transform: translateY(-2px);
}

.step-content {
  transition: opacity 0.3s, transform 0.3s;
}

.step-content.active {
  opacity: 1;
  transform: translateY(0);
}

.step-content:not(.active) {
  opacity: 0.6;
  transform: translateY(10px);
}

/* Focus styles for accessibility */
.step-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(3, 37, 76, 0.3);
}

/* Smoothing the scroll behavior */
html {
  scroll-behavior: smooth;
}
